<template>
  <v-container>
    <v-row>
      <v-col class="col-5">
        新規参入の脅威
        <v-textarea 
          v-model="fiveForces.threatNewEntrants"
          outlined
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
      <v-col class="col-2">
      </v-col>
      <v-col class="col-5">
        売り手の交渉力
        <v-textarea 
          v-model="fiveForces.bargainingPowerSuppliers"
          outlined
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
      </v-col>
      <v-col class="col-6">
        業界内の競争
        <v-textarea 
          v-model="fiveForces.competitiveRivalry"
          outlined
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
      <v-col class="col-3">
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-5">
        代替品の脅威
        <v-textarea 
          v-model="fiveForces.threatSubstitutes"
          outlined
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
      <v-col class="col-2 ">
      </v-col>
      <v-col class="col-5">
        買い手の交渉力
        <v-textarea 
          v-model="fiveForces.bargainingPowerCustomers"
          outlined
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  props: ['value', 'readonly'],
  data () {
    return {
      fiveForces: {
        threatNewEntrants: null,
        threatSubstitutes: null,
        bargainingPowerCustomers: null,
        bargainingPowerSuppliers: null,
        competitiveRivalry: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.fiveForces = this.value
    }
  },
  watch: {
    value(val) {
      this.fiveForces = val
    },
  }
}
</script>