<template>
  <div class="pt-0 current-strategy-and-action-wrap">
    <v-textarea-with-link
      v-model="current.strategicPolicy"
      label="戦略方針"
      :readonly="readonly"
      reference="CurrentStrategyAndAction_strategicPolicy.txt"
      :refDoc="true"
    ></v-textarea-with-link>

    <v-card class="measures-textfiled-area mt-7 mb-7 pb-3">
      <v-card-title>現在実施している施策</v-card-title>

      <v-card-text>
        <v-card-title class="pl-0 py-0 title-online">オンライン</v-card-title>
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0">
            <input-sample class="input-sample" :refDoc="true" reference="CurrentStrategyAndAction_measuresOnlineAttract.txt" label="オンライン"></input-sample>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mb-3">
          <v-col cols="6" class="ma-0 pa-0 pr-2">
            <v-combobox
              v-model="current.measuresWeb"
              :items="MasterData.onlineMeasures"
              item-text="onlineMeasures"
              item-value="onlineMeasures"
              outlined
              label="オンライン施策・タッチポイントの精査"
              multiple
              chips
              deletable-chips
              hide-details
              :return-object=false
              :clearable="!readonly"
              :readonly="readonly"
              class="online-combobox"
            ></v-combobox>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0 pl-2">
            <v-textarea
              v-model="current.measuresOnlineAttract"
              outlined
              auto-grow
              hide-details
              label="オンライン施策詳細"
              rows="3"
              class="reservation-site"
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-divider class="py-2"></v-divider>

        <v-card-title class="pl-0 py-0 title-ofline">オフライン</v-card-title>
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0">
            <input-sample class="input-sample" :refDoc="true" reference="CurrentStrategyAndAction_measuresOfflineAttract.txt" label="オフライン"></input-sample>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mb-3">
          <v-col cols="6" class="ma-0 pa-0 pr-2">
            <v-combobox
              v-model="current.measuresReal"
              :items="MasterData.offlineMeasures"
              item-text="offlineMeasures"
              item-value="offlineMeasures"
              :return-object=false
              outlined
              label="オフライン施策・タッチポイントの精査"
              multiple
              chips
              deletable-chips
              hide-details
              :clearable="!readonly"
              :readonly="readonly"
              class="ofline-combobox"
            ></v-combobox>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0 pl-2">
            <v-textarea
              v-model="current.measuresOfflineAttract"
              outlined
              auto-grow
              hide-details
              label="オフライン施策詳細"
              rows="3"
              class="reservation-site"
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-select
      v-model="current.measuresEvaluation"
      :items="MasterData.strategicEvaluation"
      item-text="strategicEvaluation"
      item-value="strategicEvaluation"
      :return-object=false
      label="戦略と施策評価"
      outlined
      class="strategic-evaluation"
      :clearable="!readonly"
      :readonly="readonly"
    ></v-select>
  </div>
</template>
<script>
import VTextareaWithLink from '../../atoms/VTextareaWithLink.vue';
import InputSample from '../../atoms/InputSample.vue';
import MasterData from '../../../../utils/MasterData'

export default {
  components: {
    VTextareaWithLink,
    InputSample,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      editing: null,
      current: {
        strategicPolicy: null,
        measuresOnlineAttract: null,
        measuresOfflineAttract: null,
        measuresWeb: null,
        measuresReal: null,
        measuresEvaluation: null
      },
      MasterData: {}
    }
  },
  created(){
    if(this.value) {
      this.current = this.value
    }
  },
  methods: {
    //現状の戦略と実施施策セクションの未入力チェック
    isEmpty() {
      if(!this.current) return true

      const current = this.current
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['strategicPolicy', 'measuresOnlineAttract', 'measuresOfflineAttract', 'measuresEvaluation']
      ret = questionKeys.some(i => current[i])
      if(ret) return false

      //オンライン施策・タッチポイント
      if(current.measuresWeb && current.measuresWeb.length > 0) {
        return false
      }
      //オフライン施策・タッチポイント
      if(current.measuresReal && current.measuresReal.length > 0) {
        return false
      }

      return true
    },
  },
  watch: {
    value(val) {
      this.current = val
    },
    "current.inputStatusCurrent"(val) {
      this.$store.commit('brand_input_status_current', val)
    },
  },
  mounted() {
    this.MasterData = MasterData
  }
}
</script>
<style lang="scss" scoped>
  .reservation-site{
    max-width: 700px;
  }
  .strategic-evaluation{
    width: 400px;
  }
  .title-online{
    color: #4CAF50;
  }
  .title-ofline{
    color: #FF9800;
  }

</style>
<style lang="scss">
.current-strategy-and-action-wrap {
  .measures-textfiled-area{
    background-color: #f3faff!important;
    .v-input__slot{
      background-color: white!important;
    }

    .online-combobox{
      .v-input__slot{
        .v-chip:not(.v-chip--active){
          background: #C8E6C9;
        }
      }
    }
    .ofline-combobox{
      .v-input__slot{
        .v-chip:not(.v-chip--active){
          background: #FFE0B2;
        }
      }
    }
  }

  .input-sample{
    margin-top: -3px;
  }
}
</style>
