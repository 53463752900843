<template>
  <div class="pt-5 service-wrap">
    <v-row>
      <v-col v-for="(item, i) in service.addItems" :key="item.id" cols="12" md="6">
        <!-- <v-divider></v-divider> -->
        <v-row class="ma-0 pa-0">
          <v-col align="right" class="ma-0 pa-0 pb-2">
            <input-sample class="input-sample" :refDoc="true" reference="Service_service.txt" label="サービス名・特長"></input-sample>
          </v-col>
        </v-row>
        <v-row class="align-center ma-0 pa-0">
          <v-col cols="10" md="8" class="ma-0 pa-0">
            <v-text-field
              v-model="item.serviceName"
              class="service-name"
              label="サービス名"
              :clearable="!readonly"
              :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="2" md="4" class="text-right ma-0 pa-0 pb-2">
            <v-btn
              v-show="!readonly"
              depressed
              small
              color="primary"
              @click="deleteService(i)"
            >
              <v-icon left dark dense>mdi-trash-can</v-icon>
              削除
            </v-btn>
          </v-col>
        </v-row>
        <v-textarea
          v-model="item.serviceContents"
          label="特長"
          outlined
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
      <v-col class="d-flex justify-center align-center my-16" cols="12" md="6">
        <v-btn
          v-show="!readonly"
          fab
          outlined
          color="primary"
          depressed
          @click="addService"
          width="45"
          height="45"
        >
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>
<script>
import InputSample from '../../atoms/InputSample.vue';

export default {
  components: {
    InputSample,
  },
  props: ['value', 'readonly'],
  data(){
    return{
      service: {
        addItems:[
          {
            serviceName: null,
            serviceContents: null
          },
        ],
      },
    }
  },
  created(){
    if(this.value) {
      this.service = this.value
    }
  },
  methods: {
    //診療内容セクションの未入力チェック
    isEmpty() {
      if(!this.service) return true

      const service = this.service
      let ret = false

      //追加質問項目をチェック
      if(service.addItems && service.addItems.length > 0) {
        ret = service.addItems.some(i => i.serviceName || i.serviceContents)
        if(ret) return false
      }

      return true
    },

    addService: function(){
      this.service.addItems.push({ serviceName: null, serviceContents: null })
    },
    deleteService: function(i){
      this.service.addItems.splice(i, 1)
    }
  },
  watch: {
    value(val) {
      this.service = val
    },
    "service.inputStatusService"(val) {
      this.$store.commit('brand_input_status_service', val)
    },
  }
}
</script>
<style lang="scss" scoped>
.service-wrap {
  .service-name{
    max-width: 700px;
  }

  .input-sample{
    margin-top: -3px;
  }
}
</style>