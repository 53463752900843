<template>
  <v-container class="pa-8">
    <v-row>
      <v-col class="col-6 borderd-col">
        <span class="text-h4 mr-1">Politics</span>政治的要因
        <v-textarea 
          v-model="pest.pestPolitics"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="col-6 borderd-col">
        <span class="text-h4 mr-1">Economy</span>経済的要因
        <v-textarea 
          v-model="pest.pestEconomy"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6 borderd-col">
        <span class="text-h4 mr-1">Society</span>社会的要因
        <v-textarea 
          v-model="pest.pestSociety"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="col-6 borderd-col">
        <span class="text-h4 mr-1">Technology</span>技術的要因
        <v-textarea 
          v-model="pest.pestTechnology"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  props: ['value', 'readonly'],
  data () {
    return {
      pest: {
        pestPolitics: null,
        pestEconomy: null,
        pestSociety: null,
        pestTechnology: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.pest = this.value
    }
  },
  watch: {
    value(val) {
      this.pest = val
    },
  }
}
</script>
<style lang="scss" scoped>
.borderd-col {
  border: 1px black solid;
}
</style>
