<template>
  <div class="pa-8">
    <v-expansion-panels :value="[0, 1, 2]" multiple>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="現状の戦略と実施施策" status="notInput"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <current-strategy-and-action></current-strategy-and-action>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="診療内容" status="edit"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <service></service>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="競合分析" status="done" :switch="true"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <competitors></competitors>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="5Force分析" status="done" :switch="true"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <five-forces-analysis></five-forces-analysis>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header-with-label label="PEST分析" status="done" :switch="true"></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <pest-analysis></pest-analysis>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </div>
</template>
<script>
import VExpansionPanelHeaderWithLabel from '@/components/atomic/atoms/VExpansionPanelHeaderWithLabel.vue';
import CurrentStrategyAndAction from '@/components/atomic/organisms/bp/CurrentStrategyAndAction';
import Service from '@/components/atomic/organisms/bp/Service';
import Competitors from '@/components/atomic/organisms/bp/Competitors';
import FiveForcesAnalysis from '@/components/atomic/organisms/FiveForcesAnalysis';
import PestAnalysis from '@/components/atomic/organisms/PestAnalysis';

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    CurrentStrategyAndAction,
    Service,
    Competitors,
    FiveForcesAnalysis,
    PestAnalysis,
  },
  props: {
  },
}
</script>